@import "core/index.scss";
.mapelement {
	height: 50rem;

	.mapelement-marker {
		color: $color-pink;
		position: absolute;
		bottom: 0;
		font-size: 6rem;
		width: 6rem;
		height: 6rem;
		left: -2.1rem;
	}
}