@import "core/index.scss";
.sliderelem {
	position: relative;
	
	&.loading {
		.swiper-slide:not(.swiper-slide-active) {
			display: none;
		}
		.swiper-wrapper {
			display: flex;
		}
	}
	
	.sliderelem-swiper {
		overflow: hidden;
	}

	.sliderelem-navbtn {
		position: absolute;
		top: 50%;
		z-index: 4;
		transform: translate3d(0, -50%, 0);
		font-size: 2rem;
		width: 4.6rem;
		height: 4.6rem;
		border-radius: 50%;
		background-color: $color-white;
		box-shadow: $shadow-general;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition:
			opacity $transition-hover ease,
			background-color $transition-hover ease,
			color $transition-hover ease;

		&.swiper-button-disabled {
			cursor: default;
			opacity: .8;
			color: rgba($color-text, .5);
		}

		&.prev {
			left: 0;
		}

		&.next {
			right: 0;
		}

		@include mediaMin {
			&:not(.swiper-button-disabled) {
				&:hover {
					background-color: $color-bg;
				}
			}
		}

		// position: absolute;
		// top: 50%;
		// z-index: 2;
		// transform: translate3d(0, -50%, 0);
		// font-size: 1.4rem;
		// width: 5rem;
		// height: 5rem;
		// display: flex;
		// justify-content: center;
		// align-items: center;

		// &.prev {
		// 	right: 100%;
		// }

		// &.next {
		// 	left: 100%;
		// }

		// @include media($slider-inline-arrow-width) {
		// 	background-color: rgba($color-white, .6);
		// 	box-shadow: $shadow-general;
		// 	border-radius: 50%;
		// 	width: 3rem;
		// 	height: 3rem;

		// 	&.prev {
		// 		right: auto;
		// 		left: 1rem;
		// 	}

		// 	&.next {
		// 		left: auto;
		// 		right: 1rem;
		// 	}
		// }
	}

	.sliderelem-pagination {
		position: absolute;
		bottom: 1rem;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		
		.swiper-pagination-bullet {
			display: block;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			background-color: $color-white;
			opacity: .6;
			margin: 0 .5rem;
			transition: opacity $transition-hover ease;
		}
	
			.swiper-pagination-bullet-active {
				opacity: 1;
			}
	}

}