@import "core/index.scss";
.page.stores {
	.stores-list {
		padding: 3.4rem;
		display: flex;
		flex-flow: row wrap;

		@include media {
			padding: 0 1rem 2rem;
		}
	}

		.list-store {
			width: 20%;
			padding: 1rem;
			position: relative;

			@include media {
				width: 50%;
			}

			@include mediaMin {
				&:after {
					@include pseudo;
					position: absolute;
					top: 1rem;
					left: 1rem;
					right: 1rem;
					bottom: 1rem;
					box-shadow: $shadow-big;
					opacity: 0;
					transition: opacity $transition-hover ease;
					border-radius: $radius-general;
				}

				&:hover {
					&:after {
						opacity: 1;
					}
				}
			}
		}

			.store-link {
				position: relative;
				background-color: $color-white;
				display: block;
				border: .1rem solid $color-gray-light;
				border-radius: $radius-general;
				overflow: hidden;
				z-index: 2;
			}

			.store-imagewrap {
				padding-bottom: 70%;
				position: relative;

				&.placeholder {
					height: auto;
				}
			}

			.store-image {
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.store-title {
				display: block;
				padding: 1rem 1rem 2rem;

				&.placeholder {
					height: 3rem;
					margin-top: 1rem;
					margin-bottom: 2rem;
				}
			}

	.stores-detail-top {
		background-color: $color-blue-bg;
		padding: 3.5rem 4.4rem 6.3rem;
		display: flex;
		flex-flow: row wrap;

		@include media {
			margin-top: 2rem;
		}
	}

		.top-section {
			padding-right: 9.6rem;
			max-width: 40rem;
			line-height: 1.3333em;
		}

			.section-title {
				display: block;

				&.placeholder {
					width: 20rem;
					height: 1.9rem;
				}
			}

			.section-block {
				color: $color-text-light;
				margin-top: 2rem;

				&.placeholder {
					height: 3.8rem;

					&:nth-child(1) {
						width: 30.4rem;

						&:last-child {
							width: 17rem;
							height: 13.3rem;
						}
					}

					&:nth-child(2) {
						width: 14rem;
					}

					&:nth-child(3) {
						width: 16rem;
						height: 1.9rem;
					}
				}
			}

	.stores-detail-description {
		padding: 3rem 4.4rem 0;

		.placeholder {
			width: 100%;
			height: 5rem;

			&:nth-child(2) {
				height: 3rem;
				width: 80%;
			}

			&:nth-child(3) {
				height: 6rem;
				width: 60%;
			}
		}

		&:last-child {
			padding-bottom: 4.4rem;
		}
	}

	.stores-detail-gallery {
		margin: 3rem 4.4rem 0;
		width: calc(100% - 8.8rem);

		&:last-child {
			margin-bottom: 4.4rem;
		}
	}

	.stores-detail-map {
		margin-top: 4rem;
		height: 50rem;

		@include media {
			margin-top: 0;
		}
	}
}